import React, {FunctionComponent}           from 'react'
import Layout                               from '../components/shared/layout'
import {Container, Grid, Theme, Typography} from '@material-ui/core'
import theme                                from '../theme'
import SanityImage                          from 'gatsby-plugin-sanity-image'
import PortableText                         from '../components/portableText'
import {makeStyles}                         from '@material-ui/core/styles'
import {graphql}                            from 'gatsby'


export const query = graphql`
    query PageQuery($id: String!){
        page: sanityPage(id: {eq: $id}) {
            slug {
                current
            }
            pageTitle
            headerImage {
                ...ImageData
            }
            _rawPageContent(resolveReferences: {maxDepth: 10})
        }
    }
`

interface StaticPageProps {
    data: { page: StaticPageData }
}

type StaticPageData = {
    headerImage: { asset: { fluid: any } }
    pageTitle: string
    _rawPageContent: any
}

const useStyles = makeStyles((theme: Theme) => ({
    article: {},
    articleTitle: {},
    headerContainer: {},
    byline: {},
    dateLine: {},
    bodyWrapper: {
        background: '#FFF'
    },
    bodyContainer: {
        backgroundColor: '#FFF',
        paddingTop: '2em'
    }
}))

const StaticPage: FunctionComponent<StaticPageProps> = (props: StaticPageProps): JSX.Element => {

    const classes = useStyles(theme)
    const pageContent: any = props.data.page
    return (
        <Layout helmetData={{
            pageTitle: pageContent ? pageContent.pageTitle : 'Level 22 Chambers',
            metaDescription: 'LEVEL 22 CHAMBERS is a leading commercial and administrative set based in the heart of Sydney’s legal precinct', // TODO: add to CMS the ability to set meta description for static pages
            slug: pageContent ? pageContent.slug.current : ''
        }} backgroundImage={''}>
            {
                pageContent && pageContent.headerImage && (
                                <header className={classes.headerContainer}>
                                    <SanityImage {...pageContent.headerImage}
                                                 style={{
                                                     maxHeight: '400px',
                                                     boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
                                                 }}
                                                 imgStyle={{
                                                     maxHeight: '400px',
                                                     position: 'absolute',
                                                     left: '0',
                                                     right: '0',
                                                     marginLeft: 0,
                                                     marginRight: 0
                                                 }}/>
                                </header>
                            )
            }
            <Container style={{backgroundColor: '#FFF'}} maxWidth={'lg'}>
                <Grid container justify={'center'}>
                    <Grid item xs={8}>
                        {pageContent && (
                            <section className={classes.bodyContainer}>
                                {pageContent.pageTitle && (
                                    <Typography variant={'h2'} component={'h1'} className={classes.articleTitle}>
                  <span style={{fontWeight: 500}}>
                  {pageContent.pageTitle}
                  </span>
                                    </Typography>
                                )}
                                <article
                                    className={classes.article}>
                                    {
                                        pageContent && (
                                                        <PortableText blocks={pageContent._rawPageContent}/>
                                                    )
                                    }
                                </article>
                            </section>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Layout>
    );
}

export default StaticPage
